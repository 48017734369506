var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"liste"}},[_c('HeaderTab',{attrs:{"title":_vm.$t('global.calendrier')}}),_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.processing),expression:"processing"}],staticClass:"calendar-spinner"},[_c('LoadingSpinner')],1),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-5"},[_c('label',{attrs:{"for":"lieu"}},[_vm._v(_vm._s(_vm.$t('mouvement.lieu_mouvement')))]),_c('e-select',{attrs:{"id":"mouv_resid","track-by":"uuid","label":"name","multiple":"","placeholder":_vm.labelTitle,"deselectLabel":_vm.deselectLabel,"selectedLabel":_vm.selectedLabel,"selectLabel":_vm.enter_to_select,"options":_vm.lieux,"searchable":true,"show-labels":false,"allow-empty":true},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('span',{style:(("padding-left: " + (25 * (props.option.indent || 0)) + "px;"))},[_vm._v(_vm._s(props.option.name))])]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.selected_lieux),callback:function ($$v) {_vm.selected_lieux=$$v},expression:"selected_lieux"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1)]),_c('calendar-view',{staticClass:"theme-default acte-calendar",style:(_vm.style_calendar),attrs:{"items":_vm.items,"show-date":_vm.showDate,"periodChangedCallback":_vm.setDate,"currentPeriodLabel":_vm.$t('date_range.jour'),"displayPeriodUom":_vm.period,"startingDayOfWeek":1,"locale":_vm.currentLang},on:{"click-item":_vm.goToGroupe},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headerProps = ref.headerProps;
return [[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-3"},[_c('h4',[_vm._v(_vm._s(headerProps.periodLabel))])]),_c('div',{staticClass:"col d-flex justify-content-center align-items-center"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.setShowDate(headerProps.previousPeriod)}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'long-arrow-left']}})],1),_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.setShowDate(headerProps.currentPeriod)}}},[_vm._v(_vm._s(_vm.$t('global.aujourdhui')))])]),_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.setShowDate(headerProps.nextPeriod)}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'long-arrow-right']}})],1)]),_c('div',{staticClass:"col-3"},[_c('e-select',{attrs:{"options":_vm.all_period,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t('calendar_periode.'+option)))]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t('calendar_periode.'+option)))]}}],null,true),model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1)])]]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }