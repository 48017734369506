<template>
    <main id="liste">
        <HeaderTab 
            :title="$t('global.calendrier')"
        />
        
        <div id="content">
	        <div class="box">
	        	<div class="calendar-spinner" v-show="processing">
					<LoadingSpinner />
				</div>

				<div class="row mb-3">
					<div class="col-5">
						<label for="lieu">{{ $t('mouvement.lieu_mouvement') }}</label>
						<e-select
							v-model="selected_lieux"
							id="mouv_resid"
							track-by="uuid"
							label="name"
							multiple
							:placeholder="labelTitle"
							:deselectLabel="deselectLabel"
							:selectedLabel="selectedLabel"
							:selectLabel="enter_to_select"
							:options="lieux"
							:searchable="true"
							:show-labels="false"
							:allow-empty="true"
						>
							<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.name }}</span></template>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
				</div>

				<calendar-view
					class="theme-default acte-calendar"
					:style='style_calendar'
					:items="items"
					:show-date="showDate"
					:periodChangedCallback="setDate"
					:currentPeriodLabel="$t('date_range.jour')"
					:displayPeriodUom="period"
					:startingDayOfWeek="1"
					@click-item="goToGroupe"
					:locale="currentLang"
				>
					<template #header="{ headerProps }">
						<template :header-props="headerProps">
							<div class="row mb-4">
								<div class="col-3">
									<h4>{{ headerProps.periodLabel }}</h4>
								</div>
								<div class="col d-flex justify-content-center align-items-center">
									<button class="btn btn-secondary" @click="setShowDate(headerProps.previousPeriod)"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></button>
									<div class="d-flex">
										<button class="btn btn-secondary" @click="setShowDate(headerProps.currentPeriod)" >{{$t('global.aujourdhui')}}</button>
									</div>
									<button class="btn btn-secondary" @click="setShowDate(headerProps.nextPeriod)"><font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
								</div>
								<div class="col-3">
									<e-select
										v-model="period"
										:options="all_period"
										:allow-empty="false"
										:show-labels="false"
									>
				                        <template slot="option" slot-scope="{ option }">{{ $t('calendar_periode.'+option) }}</template>
				                        <template slot="singleLabel" slot-scope="{ option }">{{ $t('calendar_periode.'+option) }}</template>
				                    </e-select>
								</div>
							</div>
						</template>
					</template>
				</calendar-view>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
    import ActeMixin from "@/mixins/Actes.js";
	import Lieux from "@/mixins/Lieux.js"
	import IntraLocation from "@/mixins/IntraLocation.js"
	import Vue from 'vue'
	import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
	require("vue-simple-calendar/static/css/default.css")
	import _uniqueId from 'lodash/uniqueId'

	export default {
		name: 'Calendar',
		mixins: [ActeMixin, Lieux, IntraLocation],
		data () {
			return {
				lieux: [],
				selected_lieux: [],
				horses_ids: null,
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				selectedLabel: this.getTrad("global.selected_label"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				labelTitle: this.getTrad("lieu.residences"),
				showDate: new Date(),
				start: new Date(),
				end: new Date(),
				items: [],
				ready: false,
				processing: false,
				period: "month",
				all_period: ['month', 'week'],
				color_groupe: {
					VAC: "#158D4D",
					VERM: "#FF7C24",
					SOINS: "#D11543",
					TEST: "#FFC031",
					TRANSP: "#54AFE2",
					ENTR: "#EB3C96",
					REPRO: "#197D8D",
					TRAIT: "#532B87",
					VENTE: "#C279D6",
					PERF: "#3A5827",
					EVAL: "#919191",
					ADMIN: "#14529B"
				},
				lieu_ready: false,
				date_ready: false
			}
		},
		
		beforeMount() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const lieux = await this.getIntraLocationsWithresidences(true, false) || []
				this.lieux = []
				let inc = 0
				lieux.forEach(lieu => {
					const temp = {
						id: lieu.intralocation_id,
						uuid: inc++,
						name: lieu.intralocation_label,
						indent: lieu.indent,
						is_lieu: false,
						default: lieu.intralocation_default
					}
					// Si c'est un lieu ou une localisation interne
					if(lieu.indent == 0) {
						temp.is_lieu = true
					}
					this.lieux.push(temp)
				})
				if(this.lieux.filter(l => l.default).length == 1) {
					this.selected_lieux.push(this.lieux.filter(l => l.default)[0])
				}
				else {
					this.selected_lieux.push(this.lieux[0])
				}
				if(this.getConfig('selectedLieuxResidenceCalendar')) {
					this.selected_lieux = this.getConfig('selectedLieuxResidenceCalendar')
				}
				this.ready = true
			},

			setShowDate(d) {
				this.showDate = d;
			},

			setDate(period) {
				this.start = period.displayFirstDate
				this.end = period.displayLastDate
				this.date_ready = true
				this.changeDate()
			},

			async changeDate() {
				if(!this.ready || !this.lieu_ready || !this.date_ready) return	
				this.processing = true
				let lieux_ids = []
				let intraloc_ids = []

				if(this.selected_lieux.length != 0){
					lieux_ids = this.selected_lieux
					.filter(l => l.is_lieu === true)
					.map(l => l.id)
					
					intraloc_ids = this.selected_lieux
						.filter(l => l.is_lieu === false)
						.map(l => l.id)
				}
				this.horses_ids = []
				if((lieux_ids.length > 0 || intraloc_ids.length > 0) && this.start && this.end) {
					let mouvements = await this.getHorseResidenceDate(lieux_ids, intraloc_ids, this.start.toDateInputValue(), this.end.toDateInputValue(), true)
					if(mouvements.data){
						mouvements = mouvements.data
					}
					for (let mouvement of mouvements) {
						this.horses_ids.push(mouvement.horse_id)
					}
				}
				if(this.horses_ids.length == 0) {
					this.horses_ids = null
				}
				if(this.period == "month") {
					const actes = await this.getNbActesByGroup(this.start, this.end, this.horses_ids)
					this.items = []
					for(let date in actes) {
						for(let groupe in actes[date]) {
							this.items.push({
								id: new Date(date).getTime() + "_" + groupe,
								startDate: new Date(date),
								title: actes[date][groupe].nb + " " + this.$t('actes_groupe.' + actes[date][groupe].code),
								style: "background-color:"+this.color_groupe[actes[date][groupe].code]+" !important"
							})
						}
					}
				}
				else if(this.period == "week") {
					const actes = await this.getNbActesByType(this.start, this.end, this.horses_ids)
					this.items = []
					for(let date in actes) {
						for(let type in actes[date]) {
							this.items.push({
								id: new Date(date).getTime() + "_" + type,
								startDate: new Date(date),
								title: actes[date][type].nb + " " + this.$t('actes_groupe.' + actes[date][type].code),
								style: "background-color:"+this.color_groupe[actes[date][type].code]+" !important"
							})
						}
					}
				}
				this.setConfig('selectedLieuxResidenceCalendar', this.selected_lieux)

				this.processing = false
			},

			goToGroupe(item) {
				const id = item.id.split('_')
				const date = new Date(parseInt(id[0])).toDateInputValue()
				const group = id[1]
				this.$router.push({ name: 'planning', params: { tab: group, date: date }})
			}
		},

		computed: {
			style_calendar() {
				return this.processing ? 'opacity: 0.2;' : ''
			},
			currentLang() {
				return Vue.i18n.locale()
			}
		},

		watch: {
			selected_lieux() {
				this.lieu_ready = true
				this.changeDate()
			}
		},

		components: {
			CalendarView,
			CalendarViewHeader,
            HeaderTab: () => import('@/components/HeaderTab'),
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		},
	}

</script>